import axios from "axios";
import LocalStorageService from "../LocalStorageService";
import store from "../../store";
import {WALLET} from "../../constants/walletConstants";
import {errorToast, successToast} from "../../helper";
import {useWalletStore} from "@/store/useWalletStore";

const _Buffer = (await import('buffer/')).Buffer
const API_URL = import.meta.env.VITE_API_URL
const DISCORD_SERVER_URL = import.meta.env.VITE_DISCORD_SERVER_URL
const walletStore = useWalletStore()
class ApiService {
    config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    getWallet() {
        let returnWallet = LocalStorageService.get(WALLET, '')

        if (returnWallet === '') {
            returnWallet = walletStore.getWallet
        }

        return returnWallet
    }

    post(url, body, header) {
        this.config.headers['x-wallet'] = this.getWallet()

        if (typeof header !== "undefined") {
            this.config.headers = {...this.config.headers, ...header}
        }

        return axios.post(`${API_URL}/api/${url}`, body, this.config).then(response => {
            if (typeof response.data === "undefined") {
                errorToast('Api response error', "Can`t get data from resource, please try again")
                return {}
            }

            if (typeof response.data.success !== "undefined") {
                successToast('Api response success', response.data.success)
                return {}
            }

            if (typeof response.data.error !== "undefined" || typeof response.data.errors !== "undefined") {
                errorToast('Api response error', response.data.error)
                return {
                    error: response.data.error
                }
            }

            return response.data
        }).catch(err => {
            //todo: resolve error

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
            }

            console.log(err.config);
        })
    }

    get(url, header) {
        this.config.headers['x-wallet'] = this.getWallet()

        if (typeof header !== "undefined") {
            this.config.headers = {...this.config.headers, ...header}
        }

        return axios.get(`${API_URL}/api/${url}`, this.config).then(response => {
            if (typeof response.data === "undefined") {
                errorToast('Api response error', "Can`t get data from resource, please try again")
                return {}
            }

            if (typeof response.data.success !== "undefined") {
                successToast('Api response success', response.data.success)
                return {}
            }

            if (typeof response.data.error !== "undefined") {
                errorToast('Api response error', response.data.error)
                return {
                    error: response.data.error
                }
            }

            return response.data
        }).catch(err => {
            //todo: resolve error

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
            }

            console.log(err.config);
        })
    }

    postv2(url, body, header) {
        this.config.headers['x-wallet'] = this.getWallet()

        if (typeof header !== "undefined") {
            this.config.headers = {...this.config.headers, ...header}
        }

        return axios.post(`${API_URL}/api/v2/${url}`, body, this.config).then(response => {
            if (typeof response.data === "undefined") {
                errorToast('Api response error', "Can`t get data from resource, please try again")
                return {}
            }

            if (typeof response.data.success !== "undefined") {
                successToast('Api response success', response.data.success)
                return {}
            }

            if (typeof response.data.error !== "undefined") {
                errorToast('Api response error', response.data.error)
                return {
                    error: response.data.error
                }
            }

            return response.data
        }).catch(err => {
            //todo: resolve error

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
            }

            console.log(err.config);
        })
    }

    getv2(url, header) {
        this.config.headers['x-wallet'] = this.getWallet()

        if (typeof header !== "undefined") {
            this.config.headers = {...this.config.headers, ...header}
        }

        return axios.get(`${API_URL}/api/v2/${url}`, this.config).then(response => {
            if (typeof response.data === "undefined") {
                errorToast('Api response error', "Can`t get data from resource, please try again")
                return {}
            }

            if (typeof response.data.success !== "undefined") {
                successToast('Api response success', response.data.success)
                return {}
            }

            if (typeof response.data.error !== "undefined") {
                errorToast('Api response error', response.data.error)
                return {
                    error: response.data.error
                }
            }

            return response.data
        }).catch(err => {
            //todo: resolve error

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
            }

            console.log(err.config);
        })
    }

    getPrice() {
        return axios.get(`${API_URL}/api/price`).then(response => {
            return response.data.price
        })
    }
}

export default new ApiService()
