import { createApp } from "vue";
import {createPinia, setActivePinia} from 'pinia'
// @ts-ignore
import App from "./App.vue";
import router from "./router";
import store from "./store";
// @ts-ignore
import en from './lang/en.json'
import { Buffer } from 'buffer'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import VueCountdown from '@chenfengyuan/vue-countdown';
import {createI18n} from "vue-i18n";

import './sass/app.scss'

import emitterPlugin from '@/plugins/emitterPlugin.js'
window['Buffer'] = Buffer

const pinia = createPinia()
setActivePinia(pinia);

const i18n = createI18n({
    locale: 'en',
    globalInjection: true,
    legacy: false,
    fallbackLocale: 'en',
    availableLocales: ['en'],
    messages: {
        en: en,
    },
})

const main = createApp(App);

main.component(VueCountdown.name, VueCountdown);

main.directive("resize", {
    beforeMount: (el, binding) => {
        const onResizeCallback = binding.value;
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        onResizeCallback({ width, height, binding });
        window.addEventListener('resize', () => {
            const width = document.documentElement.clientWidth;
            const height = document.documentElement.clientHeight;
            onResizeCallback({ width, height, binding });
        })
    },
    beforeUnmount: (el, binding) => {
    }
});


const metaManager = createMetaManager()

main.use(emitterPlugin)
main.use(metaManager)
main.use(metaPlugin)
main.use(i18n)
main.use(pinia)
main.use(router)
main.use(store)

router.isReady().then(() => {
    main.mount('#app')
})
